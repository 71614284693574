$navbar-common: (
   'height': 4.5rem,
   'sm-height': 4rem,
   'mobile-height': 4rem,

   'brand-font-size': 1.5rem,

   'link-padding': 0.75rem,

   'input-font-size': 0.95rem,

   'input-border-width': 0 0 1px,

   'link-show-border': rgba(255, 255, 255, 0.73),
   'link-show-border-offset': 2px,
   'link-show-border-height': 3px
);

$navbar-blue: map-merge($navbar-common, (
    'background': #2872ba,//#2e75b9


    'toggler-hover-background': rgba(255,255,255,0.1),
    'toggler-active-background': rgba(0,0,0,0.1),


    'link-color': rgba(255,255,255,0.92),
    'link-background': transparent,

    'link-hover-background': rgba(255,255,255, 0.1),
    'link-active-background':  rgba(0,0,0,0.1),
    'link-show-background': rgba(0,0,0,0.15),
    'link-disable-color': rgba(255,255,255,0.6),
   
    //the line separating items
    'item-border': rgba(0,0,0,0.1),
    'item-active-border': rgba(0,0,0,0.2),


    //inputs such as search
    'input-background': transparent,
    'input-color': #fff,
    'input-placeholder': #fff,

    'input-border': transparent,
    'input-focus-border': rgba(255,255,255,0.7),
    'input-hover-border': rgba(255,255,255,0.7),

    //mobile view
    'mobile-menu-border': rgba(34, 85, 117, 0.2),
    'mobile-nav-item-background': rgba(0,0,0,0.1),

    'mobile-nav-item-border': rgba(255,255,255,0.1),

));


$navbar-white: map-merge($navbar-blue, (
    'background': #fff,
    'border': bgc-color('grey', 'l2'),//#e6eaed

    'intro-background': null,
    'intro-border': #f0f0f0,

    'toggler-active-background': #e9f2fa,


    'link-color': #5e6267,
    'link-hover-color': #575a5f,
    'link-hover-background': #eef1f5,
    'link-active-color': #575a5f,
    'link-active-background': #eef1f5,
    'link-show-color': #575a5f,
    'link-show-background': #eef1f5,

    'link-show-border': #99a4bb,
  
    'item-border': null,

    'brand-font-size': 1.5rem,

    //inputs such as search
    'input-background': mix(bgc-color('secondary', 'l3') , bgc-color('secondary', 'l4')),
    'input-color': text-color('secondary', 'd2'),
    'input-placeholder': text-color('secondary', 'd2'),

    'input-font-size':1rem,

    'input-focus-background': #fff,
    'input-hover-background': bgc-color('secondary', 'l4'),

    'input-border-width': 1px,
    'input-border': transparent,
    'input-focus-border': bgc-color('default', 'm4'),
    'input-hover-border': bgc-color('secondary', 'l1'),

    'mobile-nav-item-background': rgba(232, 239, 245, 0.5),
    'mobile-link-show-color': #fff,

    'mobile-menu-border': rgba(69, 124, 168, 0.13),
    'mobile-nav-item-border': rgba(69, 124, 168, 0.13),

));


$navbar-white2: map-merge($navbar-white, (
    'link-color': #617694,
    'link-hover-color': #586a84,
    'link-hover-background': #ebf1f6,
    'link-active-color': #586a84,
    'link-active-background': #ebf1f6,
    'link-show-color': #586a84,
    'link-show-background': #ebf1f6,  

    'link-show-border': #76abd4,

    'input-background': transparent,
    'input-color': #535865,

    'input-focus-background': null,
    'input-hover-background': null,

    'input-border-width': 0 0 1px,
    'input-focus-border': rgba(0,0,0,0.25),
    'input-hover-border': rgba(0,0,0,0.175),

    'input-placeholder': #535865
));


$navbar-light: map-merge($navbar-white, (
    'background': whitesmoke,
    'intro-border': null,

    'toggler-active-background': #f0f5f9,

    'link-color': rgba(0, 0, 0, 0.5),
    'link-hover-background': rgba(232, 239, 245, 0.5),
    'link-active-background': rgba(232, 239, 245, 0.5),
    'link-show-background': rgba(232, 239, 245, 0.5),

    'link-show-border': null,
    'mobile-nav-item-background': null,

));




$navbar-dark: map-merge($navbar-blue, (
    'background': #4d5762
));

$navbar-color: map-merge($navbar-dark, (
    'link-color': rgba(255,255,255,0.95),
    'link-hover-background': rgba(255, 255, 255, 0.125),
    'link-active-background': rgba(0,0,0,0.075),
    'link-show-background': rgba(0,0,0,0.1),
));


$navbar-orange: map-merge($navbar-color, 
(
    'background': #d0812c,
));

$navbar-purple: map-merge($navbar-color, 
(
    'background': #63529f,
));
$navbar-plum: map-merge($navbar-color, 
(
    'background': #824c80
));

$navbar-secondary: map-merge($navbar-color, 
(
    'background': #678
));
$navbar-teal: map-merge($navbar-color, 
(
    'background': #017878,
));
$navbar-darkblue: map-merge($navbar-color, 
(
    'background': #1166a6,
));

$navbar-slategrey: map-merge($navbar-color, 
(
    'background': slategrey
));
$navbar-green: map-merge($navbar-color, 
(
    'background': #2b9870
));
$navbar-cadetblue: map-merge($navbar-color, 
(
    'background': #4f9193
));
$navbar-darkgreen: map-merge($navbar-color,
(
    'background': #5b8c5b
));

$navbar-brown: map-merge($navbar-color, 
(
    'background': #a97047,
    'link-color': #fff,
    'link-hover-background': rgba(0,0,0, 0.12),
));


$navbar-skyblue: map-merge($navbar-color, 
(
    'background': #4aa0df,
    'intro-background': #3b91d1,

    'link-color': #fff,
    //'link-color': rgba(0, 0, 0, 0.5),//or make it dark

    'link-hover-color': rgba(255, 255, 255, 0.87),
    'link-hover-background': rgba(0, 0, 0, 0.125),

    'link-active-color': rgba(255, 255, 255, 0.93),
    'link-active-background': rgba(0, 0, 0, 0.15),

    'link-show-color': #fff,
    'link-show-background': rgba(0, 0, 0, 0.15),

    'mobile-nav-item-border': rgba(255,255,255,0.22),

    'input-background': rgba(255,255,255,0.1),
    'input-border-width': 1px,
    'input-hover-border': rgba(255,255,255,0.25),
    'input-focus-border': rgba(255,255,255,0.4),
    'input-focus-background': rgba(255,255,255,0.05),
));




//light navbars
$navbar-lightblue: map-merge($navbar-light, 
(
    'background': #f2f8fd,
    'border': #dfedf8,
    
    'intro-background': #fdfeff,
    'intro-border': #e5edf5,

    'link-color': #45749a,

    'link-hover-color': #fff,
    'link-hover-background': #5c86a8,

    'link-active-color': #fff,
    'link-active-background': #5c86a8,

    'link-show-color': #fff,
    'link-show-background': darken(#5c86a8, 4%),

    
    'mobile-link-show-color': #fff,    

    'mobile-menu-border': #dae5ee,
    'mobile-nav-item-border': #dae5ee,

));

$navbar-lightpurple: map-merge($navbar-light, 
(
    'background': #f2f1f9,
    'border': #e8e5f0,

    'intro-background': #fdfdfe,
    'intro-border': #efedf5,

    'link-color': #6f609a,

    'link-hover-color': #fff,
    'link-hover-background': #7f73c6,

    'link-active-color': #fff,
    'link-active-background': #7f73c6,

    'link-show-color': #fff,
    'link-show-background': darken(#7f73c6, 4%),

    'mobile-link-show-color': #fff,

    'mobile-menu-border': #f2eff7,
    'mobile-nav-item-border': #f2eff7,
));

$navbar-lightgreen: map-merge($navbar-light, 
(
    'background': #effced,
    'border': #e7f1e5,

    'intro-background': #fcfffc,
    'intro-border': #e7f1e5,

    'link-color': #5c7164,

    'link-hover-color': #fff,
    'link-hover-background': #5fa267,

    'link-active-color': #fff,
    'link-active-background': #85c28c,

    'link-show-color': #fff,
    'link-show-background': darken(#5fa267, 4%),

    'mobile-link-show-color': #fff,

    'mobile-menu-border': #eff7f0,
    'mobile-nav-item-border': #eff7f0,
));

$navbar-lightgrey: map-merge($navbar-light, 
(
    'background': #f4f5f7,
    'border': null,

    'intro-background': #e4e5e7,

    'link-color': #60646b,

    'link-hover-color': #fff,
    'link-hover-background': #79848a,

    'link-active-color': #fff,
    'link-active-background': #79848a,

    'link-show-color': #fff,
    'link-show-background': darken(#79848a, 4%),

    'mobile-link-show-color': #fff,

    'mobile-menu-border': #f1f3f3,
    'mobile-nav-item-border': #f1f3f3,
));


$navbar-lightyellow: map-merge($navbar-light, 
(
    'background': #fffcdc,
    'border': #fbf3c9,
    'intro-background': #f8f0ad,

    'link-color': rgba(0, 0, 0, 0.6),

    'link-hover-color': #fff,
    'link-hover-background': #b89a8b,

    'link-active-color': #fff,
    'link-active-background': #b89a8b,

    'link-show-color': #fff,
    'link-show-background': #bc8e80,
    
    'mobile-link-show-color': #fff,

    'mobile-menu-border': #f6f2f0,
    'mobile-nav-item-border': #f6f2f0,
));
$navbar-khaki: map-merge($navbar-lightyellow, 
(
    'background': khaki,
    'border': null,
    'intro-background': #e1d56e,

    'link-color': #7c6348,


    'mobile-nav-item-background': #bc8e80,
    'mobile-link-show-color': #fff,

    'mobile-menu-border': rgba(0,0,0,0.125),
    'mobile-nav-item-border': rgba(0,0,0,0.125),
));


//$navbar-lightgradient1: map-merge($navbar-light, 
//(
//    'background': #dedaec,
//    'background-image': linear-gradient(to right, rgb(205, 202, 216) 0%, rgb(185, 209, 230) 25%, rgb(214, 206, 221) 50%, rgb(215, 207, 223) 75%, rgb(213, 216, 232) 100%),
//    'intro-background': rgba(255, 255, 255, 0.27),
//));




